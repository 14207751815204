import ig from './media/ig.png'
import { useState } from 'react'
import { Squash as Hamburger } from 'hamburger-react'

function Navbar({handleScrollTo}) {

    const [ isOpen, setOpen ] = useState(false)

    function closeAndScroll(id){
        setOpen(false)
        handleScrollTo(id)
    }
   

    return ( 
        <div id='nav'
            className={`fixed top-0 w-full z-50 overflow-hidden transform-h duration-500 bg-[#c85004] ${ isOpen ? 'h-[100svh]' : 'h-[8svh]'}`}
        >
            <div className='max-w-[1300px] mx-auto '>

                <div className='h-[8svh] w-full px-4 grid grid-cols-5 place-content-center z-50'>
                   
                    <div className='col-span-2 place-self-center mr-auto'>
                        <Hamburger size='26' color='white' toggled={isOpen} toggle={setOpen} />
                    </div>
                    
                    <div className='col-span-1 place-self-center sm:hover:animate-elevate'>
                      <button onClick={()=>handleScrollTo('home')} className={`font-bold text-white text-[26px] leading-tight ${isOpen && 'animate-fadeout'} `}>VHR</button>
                    </div>

                    
                    <a className={`col-span-2  place-self-center ml-auto pr-2  ${isOpen && 'animate-fadeout'} `} href='https://www.instagram.com/vasquezhomerepairs/'>
                        <img 
                            className='h-[32px] sm:hover:animate-elevate'
                            alt='ig' 
                            src={ig} 
                        /> 
                    </a>                   


                </div>

                <div className='h-[93svh] w-full bg-[#c85004] '>

                    <div className={`w-full text-center flex flex-col justify-between text-2xl font-extrabold text-white ${!isOpen && 'animate-fadeout-fast'}`}>
                        
                        <button onClick={()=>closeAndScroll('home')} className='w-full h-[12.5svh] grid shadow-sm sm:hover:animate-elevate'>
                            <h2 className='m-auto sm:hover:animate-elevate'>Home</h2>
                        </button>

                        <button onClick={()=>closeAndScroll('services')} className='w-full h-[12.5svh] grid shadow-sm sm:hover:animate-elevate'>
                            <h2 className='m-auto sm:hover:animate-elevate'>Services</h2>
                        </button>

                        <button onClick={()=>closeAndScroll('mission')} className='w-full h-[12.5svh] grid shadow-sm sm:hover:animate-elevate'>
                            <h2 className='m-auto sm:hover:animate-elevate'>Mission</h2>
                        </button>

                        <button onClick={()=>closeAndScroll('contact')} className='w-full h-[12.5svh] grid shadow-sm sm:hover:animate-elevate'>
                            <h2 className='my-auto sm:hover:animate-elevate'>Contact</h2>
                        </button>

                        <button onClick={()=>closeAndScroll('faq')} className='w-full h-[12.5svh] grid shadow-sm sm:hover:animate-elevate'>
                            <h2 className='my-auto sm:hover:animate-elevate'>FAQs</h2>
                        </button>

                        <button onClick={()=>closeAndScroll('gallery')} className='w-full h-[12.5svh] grid shadow-sm sm:hover:animate-elevate'>
                            <h2 className='m-auto sm:hover:animate-elevate'>Gallery</h2>
                        </button>



                    </div>

                    


                </div>

            </div>

        </div>     
     );
}

export default Navbar;