import furniture_icon from '../media/black/furniture.png'
import tv_icon from '../media/black/tv.png'
import barbell_icon from '../media/black/barbell.png'
import sink_icon from '../media/black/sink.png'

const reviews = [
    {
      "id": 1,
      "name": "Sarah",
      "neighborhood": "Forest Hills, Queens",
      "service_type": "Furniture assembly",
      "icons":[ furniture_icon ],
      "long_review": "I can't recommend Luis enough! He came over to my apartment to assemble an Ikea Wardrobe, and he did it in no time. Luis was super friendly and professional, and he made sure everything was perfect before leaving. Plus, his rates were very reasonable. If you need any handyman work done in the area, definitely give him a call!"
    },
    {
      "id": 2,
      "name": "Mike",
      "neighborhood": "Williamsburg, Brooklyn",
      "service_type": "TV mounting and Gym equipment assembly",
      "icons":[ tv_icon, barbell_icon ],
      "long_review": "Luis is a lifesaver! I recently moved into a new apartment in Williamsburg and had a list of things that needed to be done. From mounting my TV to assembling some new gym equipment, Luis and his team handled it all with ease. He was punctual, courteous, and did a fantastic job. It's hard to find reliable handymen in this area, but Luis exceeded all expectations. Highly recommended!"
    },
    {
      "id": 3,
      "name": "Viv",
      "neighborhood": "Ridgewood, Queens",
      "service_type": "Sink faucet replacement",
      "icons":[ sink_icon ],
      "long_review": "I hired Luis to help replace my kitchen faucet and was very happy with the result. He was quick, professional, and took time to have me inspect the finished product after. Highly recommend working with him!"
    }
  ]
    

export default reviews