import ReviewCard from './ReviewCard';
import reviews from './data/reviews'

function ReviewSection() {

    const renderReviews = reviews.map( r => {
        return <ReviewCard key={r.id} review={r} />
    })
 

    return ( 

        <div className='relative pt-[10svh] bg-[#f3f2f0]'>
            
            <div className=' max-w-[1300px] mx-auto flex flex-col gap-10'>


                <div  className='sticky z-40 top-[8svh] w-full flex justify-between items-center bg-[#f3f2f0] '>
                    <h1 className='hdr'>Reviews</h1>
                </div>

                <div 
                    className={`my-auto flex overflow-x-auto items-center w-full divide-x-2  py-10`}>
                    
                    {renderReviews}

                </div> 

            </div>

        </div>
    );
}

export default ReviewSection;