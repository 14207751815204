import data from './data/data'
import ServCard2 from './ServCard2';

function Services() {



    const renderServices = data.map( d =>{

        return <ServCard2 key={d.id} data={d} />
    })

    return ( 
        <div id='services'
            className='relative pt-[7svh] '
        >
            <div className='relative max-w-[1300px] mx-auto'>

                <div  className='hdr bg-white'>
                    <h1 className=''>Our Services</h1>
                </div>

                <div className='grid sm:grid-cols-2 sm:gap-2 sm:p-2 lg:grid-cols-4 lg:gap-6 lg:p-6 '>
                    {renderServices}
                </div>

            </div>

        </div>

    );
}

export default Services;