
import arrow from './media/black/icons8-chevron-down-100 (2).png'

function Question({faq, selected, changeSelected, handleScrollTo}) {

    
    const { question, answer, buttons, id } = faq

    const renderBtns = buttons.map( b => {
        return <button key={id} onClick={()=>handleScrollTo(b.to)} className='bg-[#f3f2f0]  text-[10px] sm:text-[14px] px-2 py-1   border border-black rounded-full font-semibold shadow-sm sm:hover:animate-elevate'>{b.text}</button>
    })

    return ( 

    <div   className={`bg-white w-full max-w-[600px] mx-auto text-black transform-h duration-200 overflow-hidden ${selected === id ?'h-[140px]':'h-[40px]' } rounded-2xl border border-black shadow-xl hover:cursor-pointer`}>
        
        <div
            onClick={()=>changeSelected(id)}
            className='h-[40px] flex items-center justify-between p-4 '
        >

            <p className='text-left tracking-tight text-[12px] font-semibold sm:text-lg '>{question}</p>

            <img className={`h-[15px] transform ${selected === id ? 'rotate-180 duration-100' : 'rotate-360 duration-100'}`} alt='arw' src={arrow} />
       
        </div>

        <div
            className={`h-[80px] flex flex-col gap-2 justify-center px-4 py-4 animate-fade-in ${selected !== id && 'hidden'}`}
        >
            <p className='w-full text-left text-[12px] sm:text-[14px] font-light tracking-tight leading-tight'>{answer}</p>
            <div className={`${renderBtns.length < 1 && 'hidden'} w-full flex gap-1 justify-end py-1 px-2`}>{renderBtns}</div>

        </div>
    </div> 
);
}

export default Question;