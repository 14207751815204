function ReviewCard({review}) {

    const { name, long_review, neighborhood, service_type, icons } = review
    
    const renderIcons = icons.map( i => {

        return <img 
                    className='aspect-square h-[25px]'
                    key={icons.indexOf(i)} 
                    alt={service_type} 
                    src={i} 
                    loading="lazy"
                    />
    })
    
    return ( 
            <div 
                className='
                            relative aspect-square h-[90vw] max-h-[500px] xl:max-h-[400px]  p-8
                            grid grid-rows-6
                            
                        '
            >
                
                <p className=' row-span-1 font-semibold'>{name} <span className='font-light' >- {neighborhood}</span></p>

                <p className='row-span-4 font-extralight text-xs sm:text-sm md:text-md px-2'>{long_review}</p>

                <div className='row-span-1 flex gap-2 items-center pl-2 place-content-center '>
                    {renderIcons}
                    <p className='text-[12px] pr-5 font-semibold'>{service_type}</p>
                </div>
            </div>

     );
}

export default ReviewCard;