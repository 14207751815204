const faqData = [
    {
      id: 1,
      question: "What services do you offer?",
      answer: "We offer services such as furniture assembly, TV mounting, sink faucet replacements, and more. ",
      buttons: [
        {
            id: 1,
            text: 'Services',
            to:'services'
        },
      ]
    },
    {
      id: 2,
      question: "How quickly can I schedule an appointment?",
      answer: "Appointments are typically scheduled for the next day, with same-day options available based on service/location.",
      buttons: [
        {
            id: 2,
            text: 'Get in touch',
            to: 'contact'
        }
      ]
    },
    {
      id: 3,
      question: "How do you charge for your services?",
      answer: "Pricing depends on the service and location. Contact us for a quote.",
      buttons: [
        {
            id: 1,
            text: 'Services',
            to:'services'
        },
      ]
    },
    {
      id: 4,
      question: "Are there any additional fees?",
      answer: "Additional fees may include same-day service and travel fees. We believe in transparent pricing.",
      buttons: []
    },
    {
      id: 5,
      question: "How do I schedule an appointment?",
      answer: "You can schedule via text, email, or our website form.",
      buttons: [
        {
            id: 2,
            text: 'Get in touch',
            to: 'contact'
        }
      ]
    },
    {
      id: 6,
      question: "What forms of payment do you accept?",
      answer: "We accept cash, card payments, and Apple Pay.",
      buttons: []
    },
    {
      id: 7,
      question: "Can you provide an invoice for your services?",
      answer: "Yes, we can provide detailed invoices for all services rendered.",
      buttons: [
        {
            id: 2,
            text: 'Get in touch',
            to: 'contact'
        }
      ]
    },
    {
      id: 8,
      question: "What area do you serve?",
      answer: "We serve Ridgewood, Bushwick, most of Queens, and northern Brooklyn.",
      buttons: []
    },
    {
      id: 9,
      question: "Do you charge for travel outside your service area?",
      answer: "Yes, a travel fee may apply for areas outside our designated service area.",
      buttons: []
    }
  ];
  
  
export default faqData;