import door from './media/image/door.png'
import knob from './media/image/knob.png'
import frames from './media/image/frames.png'
import rack1 from './media/image/rack.png'
import rack2 from './media/image/rack2.png'
import sink from './media/image/sink.png'
import pec from './media/image/pec.png'
import GalleryTile from './GalleryTile'



function Gallery() {

    const image = [ { image: frames, text:'Frame Mounting' }, { image: door, text:'Door Replacement' }, { image: knob, text:'Door Hardware Replacement' }, { image: rack1, text:'Gym Equipment Assembly' }, { image: rack2, text:'Gym Equipment Assembly' }, { image: sink, text:'Sink Faucet Replacement' }, { image: pec, text:'Gym Equipment Assembly' } ]

    const renderImages = image.map( i => {
      return <GalleryTile key={image.indexOf(i)} i={i} />
    })

    return ( 
        <div id='gallery'
          className='relative py-[7svh] bg-[#f3f2f0]   '

        >
          <div className='max-w-[1300px] mx-auto bg-[#f3f2f0]'>

              <div  className='hdr'>
                  <h1 className=''>Gallery</h1>
              </div>

            <div
              className={`my-auto  flex overflow-x-auto items-center gap-6 p-6 w-full`}
            >
              {renderImages}  
            </div> 

          </div>


  
        </div>
     );
}

export default Gallery;