import logo from './media/LOGO.png'

function Footer({handleScrollTo}) {


    return ( 
        <div id='footer'
            className=' bg-[#c85004]'
        >
            <div className='w-full max-w-[1300px] mx-auto grid gap-6 p-6  '>

                <div className='text-sm flex flex-col md:flex-row justify-around  gap-4 text-white '>
                    

                    <div className='flex flex-col'>
                        <p>Learn</p>
                        
                        <div
                            className='px-2 grid '
                        >

                            <button onClick={()=>handleScrollTo('services')} className='underline text-left' >Services</button>
                            <button onClick={()=>handleScrollTo('mission')} className='underline text-left' >Mission</button>

                        </div>
                    </div>
                
                    <div
                        className='flex flex-col w-fit'

                    >

                        <p>Contact Us</p>

                        <div
                            className='px-2 grid w-fit'
                        >
                                <a className='underline' href='mailto:luisvasquez.vhr@gmail.com?subject=Inquiry'>luisvasquez.vhr@gmail.com</a>
                                <a className='underline' href='sms:+18457090116' >845 709 0116</a>

                        </div>     

                    </div>


                    <div
                        className='flex flex-col '

                    >


                        <p>Connect</p>

                        <a className='underline ml-2' href='https://www.instagram.com/vasquezhomerepairs/'>Instragram</a>


                    </div>




                </div>

                <div className='w-full border-t-2 border-white grid'>

                    <button onClick={()=>handleScrollTo('home')} className=' grid place-content-center'>

                        <img className='h-[60px] mt-5' alt='logo' src={logo} />
                    </button>

                    <p className='mr-8 text-[10px] text-gray-900 text-right w-full'>icons by <a className='underline' href='https://icons8.com/'>icons8</a></p>
                
                </div>

            </div>




            



        </div>)
}

export default Footer;