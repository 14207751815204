import { useState } from 'react'
import data from './data/data'
import emailjs from 'emailjs-com'

function Form({toggleForm, isOpen}) {

    const initialInfo = {
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        zip: '',
        locationType:'residential',
        serviceType: '',
        serviceDesc: '',
        notes: '',
    }

    const [ serviceInfo, setServiceInfo  ] = useState(initialInfo) 
    const [ displaySent, setSent ] = useState(false)

    const renderDataOptions = data.map( d =>{
        return <option key={d.id}>{d.service_name}</option>
    })

    function updateFormData(e){
        const copy = {...serviceInfo}
        copy[e.target.name] = e.target.value
        setServiceInfo(copy)
    }

    function updateZip(e){
        const copy = {...serviceInfo}
        if( /^\d{0,5}$/.test(e.target.value) ){
            copy[e.target.name] = e.target.value
            setServiceInfo(copy)
        }
        
    }

    function sendEmail(e){
        e.preventDefault()
        emailjs.send(
            process.env.REACT_APP_SERVICE_ID,
            process.env.REACT_APP_TEMPLATE_ID,
            serviceInfo,
            process.env.REACT_APP_USER_ID
        ).then( res => {
            if(res.status === 200){
                setTimeout(()=>{
                    setSent(false)
                    toggleForm()
    
                }, 3000 )
                setSent(!displaySent)
                
                setServiceInfo(initialInfo)
            }
        })
    }


    return ( 

        <div className={`${!isOpen && 'hidden'} fixed top-0 h-[100svh] w-full grid place-content-center z-50 animate-fadein-fast`}>
            
            <form onSubmit={sendEmail} className={`grid grid-cols-6 gap-2 p-4 md:p-10 text-sm bg-white text-black max-w-[1000px] relative z-20 ${displaySent && 'hidden'}`}>

                <div className='col-span-6 flex justify-between items-center'>
                    <p className=' text-xl'>Customer Info</p>
                </div>

                <input required name='firstName' value={serviceInfo.firstName} onChange={updateFormData} placeholder="First name*"/>
                <input name='lastName' value={serviceInfo.lastName} onChange={updateFormData} placeholder="Last name"/>
                <input type='tel' pattern='[0-9]{10}|[0-9]{3} [0-9]{3} [0-9]{4}|\([0-9]{3}\) [0-9]{3}-[0-9]{4}' name='phone' value={serviceInfo.phone} onChange={updateFormData} className='col-span-6' placeholder="Phone"/>
                <input required type='email' name='email' value={serviceInfo.email} onChange={updateFormData} className='col-span-6' placeholder="Email*"/>



                <p className='col-span-6 text-xl mt-2'>Service Request Info</p>

                <div className=' col-span-6 flex justify-between'>

                    <p>Location Type: </p>
                    <div className='flex gap-2 col-span-3 items-center'>
                        <label>Residential</label>
                        <input checked required type='radio' name='locationType' value='residential' onChange={updateFormData} />
                    </div>

                    <div className='flex gap-2 col-span-3  items-center'>
                        <label>Commercial</label>
                        <input required type='radio' name='locationType' value='commercial' onChange={updateFormData} />
                    </div>

                </div>

                <input required minLength='5' maxLength='5' name='zip' value={serviceInfo.zip} onChange={updateZip} className='col-span-6' placeholder="Zip Code*"/>



                <select name='serviceType' value={serviceInfo.serviceType} onChange={updateFormData} required className='border border-black p-1 col-span-6'>
                    {[<option key='98'>Select Service Type</option>, ...renderDataOptions, <option key='99'>Other</option>]}
                </select>

                <textarea required name='serviceDesc' value={serviceInfo.serviceDesc} onChange={updateFormData} className='col-span-6 h-[100px] border border-black p-1' placeholder="Description*"/>

                <p className='col-span-6 text-xl mt-2'>More</p>
                <textarea name='notes' value={serviceInfo.notes} onChange={updateFormData}  className='col-span-6 h-[100px] border border-black p-1' placeholder="Notes"/>

                <div className='my-4 col-span-6 grid grid-cols-2 gap-2 max-w-[600px] place-content-center'>
                    <button onClick={toggleForm} type='button' className='py-2 text-lg  text-black border border-black rounded-xl font-bold '>back</button>
                    <button   className='py-2 text-lg bg-[#c85004] text-white border border-black rounded-xl font-bold '>Submit</button>
                </div>

            </form>

            <div className={`${!displaySent && 'hidden'} relative z-50 text-white px-10 text-center`}>
                <h1 className='relative z-50 text-[40px] text-white'>Success!</h1>
                <p>Your message has been sent. Expect an email from us soon. Thank you!</p>
            </div>

            <div className='bg-black absolute top-0 h-full w-full opacity-80 z-0'></div>
        
        </div>



    );
}

export default Form;