import hammer from './media/hammer.png'
import hammerhead from './media/hammerhead.png'

function Home({handleScrollTo}) {


    return (
        <div id='home'
            className='pt-[12svh] w-full grid relative z-10 bg-[#c85004]'
        >
            <div className='grid gap-2'>

            <h2 className='text-[24px] sm:text-[40px] font-semibold text-white leading-tight tracking-tight text-center uppercase font-serif  '>
                Vasquez Home Repairs
            </h2>

            <h1 className='text-[12px] sm:text-[24px] tracking-tight font-semibold text-white leading-tight text-center uppercase font-serif  '>
                NYC Handyman Services
            </h1>

            </div>

            <div id='hero-image'
                className='w-full relative z-0 bg-[#c85004]'>

                <img className='bg-cover h-[60svh] mb-[6svh] m-auto lg:hidden' loading='eager' alt='hand' src={hammer} />
                <img className='bg-cover h-[60svh] m-auto animate-fadein-fast hidden lg:block' loading='eager' alt='hand' src={hammerhead} />

                <div className='absolute bottom-1/3 w-full grid place-content-center'>
                    
                    
                    <button
                        onClick={()=>handleScrollTo('contact')}
                        className='
                            sm:hover:animate-elevate
                            bg-white py-2 px-20 rounded-xl
                            font-bold text-lg border border-black
                            drop-shadow-2xl tracking-tight
                        '
                    >Contact us</button>
                </div>

            </div>

        </div>
    );
}

export default Home;