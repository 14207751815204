import Footer from "./Footer";
import Home from "./Home";
import MissionPage from "./MissionPage";
import Services from "./Services";
import Contact from "./Contact";
import Navbar from "./Navbar";
import Gallery from "./Gallery";
import ReviewSection from "./ReviewSection";

import { useRef, useState } from 'react'
import Form from "./Form";
import FAQ from "./FAQ";


function App() {

  const listRef = useRef(null)
  const [ formIsOpen, setFormOpen ] = useState(false)

  function toggleForm(){
    setFormOpen(!formIsOpen)
  }

  function handleScrollTo(id){
    if(listRef.current){
      const section = document.getElementById(id)
      section.scrollIntoView({behavior: 'smooth', block: 'start'})
    }
  }

  return (
    <div className="relative w-full">

      <Navbar handleScrollTo={handleScrollTo} />

      <Home handleScrollTo={handleScrollTo} />

      <Form toggleForm={toggleForm} isOpen={formIsOpen} />

      <div ref={listRef} className='w-full'>

        <Services />

        <ReviewSection />
      
        <MissionPage />

        <Contact  toggleForm={toggleForm} /> 


        <FAQ handleScrollTo={handleScrollTo}/> 

        <Gallery />

      </div>

      <Footer handleScrollTo={handleScrollTo} />
        
    </div>
  );
}

export default App;
