
import drywall from '../media/resized_images/repair.jpg'
import furnitureAssembly from '../media/resized_images/furniture-assembly.jpg'
import gym from '../media/resized_images/gmy.jpg'
import tv from '../media/resized_images/tvmount.jpg'
import photos from '../media/resized_images/photos3.jpg'
import sink from '../media/resized_images/sink.jpg'
import door from '../media/resized_images/door.jpg'
import toilet from '../media/resized_images/toilet.jpg'

const data = [
    {
      id: 0,
      service_name: 'Furniture Assembly',
      image: furnitureAssembly,
      desc: "Relax while we assemble your furniture with care."
    },  
    {
      id: 1,
      service_name: 'Gym Equipment Assembly',
      image: gym,
      desc: "We'll set up your home gym hassle-free."
    }, 
    {
      id: 2,
      service_name: 'TV Mounting',
      image: tv,
      desc: "Expert TV mounting for the perfect setup."
    },   
    {
      id: 3,
      service_name: 'Framed Photos & Art Mounting',
      image: photos,
      desc: "Showcase memories with professional mounting."
    },
    {
      id: 4,
      service_name: 'Water Fixture Replacements',
      image: sink,
      desc: "Upgrade your fixtures with ease."
    },       
    {
        id: 5,
        service_name: 'Door Replacements',
        image: door,
        desc: "Upgrade your home's doors with our seamless replacement service."
    },
    {
      id: 6,
      service_name: 'Toilet',
      image: toilet,
      desc: "Effortless toilet installation by our experts."
    },
    {
      id: 7,
      service_name: 'Drywall Repair',
      image: drywall,
      desc: "Seamless wall restoration services."
    }
  ]

export default data;