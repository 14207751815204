import fillerImage from './media/servicesHeader.png'

function MissionPage() {


    return ( 
        <div id='mission'
            className='relative py-[10svh] bg-[#c85004] text-white min-h-[70svh] '
        >
            <div className=' max-w-[1300px] mx-auto flex flex-col gap-10'>

                <div  className='sticky z-50 top-[7svh] w-full flex justify-between items-center  bg-[#c85004] '>
                    <h1 className='hdr'>Mission</h1>
                </div>

                <img className='mx-10 drop-shadow-xl border border-black ' alt='mission' src={fillerImage} />

                <div className={` px-10  text-left grid place-content-center gap-2 `}>
                    <p className='text-[21px] font-semibold'>We take pride in being your dependable small business handyman service.</p>
                    <p className='text-[17px] font-extralight'> We aim to provide professional and reliable solutions that enhance your home atmosphere. With a commitment to top-notch craftsmanship and personalized attention, we prioritize your home projects. Count on us for all your handyman needs as we diligently work to deliver excellence, one project at a time.</p>
                </div>


            </div>

        </div>
    );
}

export default MissionPage;