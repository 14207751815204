import faqData from "./data/faqData";
import Question from "./Question";
import { useState } from 'react'

function FAQ({handleScrollTo}) {

    const [ selected, setSelected ] = useState(0) 

    function changeSelected(id){
        if(id !== selected) setSelected(id)
        else setSelected(0)
    }

    const renderFaqs = faqData.map( f => {
        return <Question key={f.id} faq={f} selected={selected} changeSelected={changeSelected} handleScrollTo={handleScrollTo} />
    })






    return (    
    <div id='faq'
        className='relative py-[10svh] bg-[#c85004] text-white min-h-[70svh] '
    >
        <div className='max-w-[1300px]  mx-auto grid gap-10'>

            <div className='hdr bg-[#c85004] '>
                <h1 className=''>FREQUENTLY ASKED QUESTIONS</h1>
            </div>  

            <div className='grid px-5 gap-4 '>

                {renderFaqs}
            </div>


        
        </div>

    </div>  );
}

export default FAQ; 