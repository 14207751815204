function GalleryTile({i}) {
    const { image, text } = i
    return ( 

            <div className='relative aspect-square h-[35svh] border border-black rounded-2xl'>
                <img className='object-cover h-full w-full rounded-2xl relative z-0'  alt='gal' src={image} />
                <p className='absolute bottom-4 p-2 text-white text-center w-full backdrop-blur-xl '>{text}</p>
            
            </div>
           

    );
}

export default GalleryTile;