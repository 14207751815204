function ServCard2({data}) {
    const { id, service_name, image, desc} = data
    return ( 
        <div className='relative '>

            <img className={`
                    w-full
                    h-full
                    object-cover
                    ${parseInt(id)% 2 !== 0 && 'grayscale'} 
                `} 
                alt={service_name} 
                src={image} 
                loading='lazy'
            />

            <div className='text-white backdrop-blur-xl absolute xs sm:sm md:md lg:lg xl:xl shadow-xl '>
                <h3>{service_name}</h3>
                <p>{desc}</p>
            </div>

        </div>
     );
}

export default ServCard2;