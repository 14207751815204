import textMessage from './media/black/icons8-topic-100.png'
import email from './media/black/icons8-email-100.png'
import form from './media/black/icons8-form-100 (1).png'

function Contact({ toggleForm}) {

    return ( 
        <div id='contact'
            className='relative py-[10svh] bg-white'
        >
            <div className='max-w-[1300px] bg-white mx-auto'>

                <div  className='hdr '>
                    <h1 className=''>Contact</h1>
                </div>  


                <div className='grid place-content-center px-5 '>

                    <div className='text-white text-sm rounded-xl p-4 gap-2'>

                        <div className={`blurbsContainer `}>

                            <a href={'sms:+18457090116'} className='blurb'>
                                <img alt='text' src={textMessage} /> 
                                <h3>Text</h3>
                                <p>Questions? Shoot us a text and we'll promptly respond.</p>
                                <p>845 709 0116</p>
                                <p className='money'>Text Us {'>'}</p>
                            </a>

                            <a  className='blurb' href='mailto:luisvasquez.vhr@gmail.com?subject=Inquiry'>
                                <img alt='email' src={email} /> 
                                <h3>Email</h3>
                                <p>Prefer email? Drop us a message and let's chat!</p>
                                <p>luisvasquez.vhr@gmail.com</p>
                                <p className='money'>
                                    Email Us {'>'}
                                </p>
                            </a>

                            <button onClick={toggleForm} className='blurb text-left'>
                                <img alt='form' src={form} /> 
                                <h3>Form </h3>
                                <p>Our form simplifies everything. Fill it out, and expect a quick response.</p>
                                <p className='text-[17px] text-[#c85004] font-bold text-center mr-auto rounded-xl'>Fill out form {'>'}</p>

                            </button>

                        </div>


                        
                            
                    </div>


                    
                </div>     

            </div>

         

        </div> 
    );
}

export default Contact;